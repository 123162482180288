import React from "react"
import { Link } from "gatsby"

function NavLink({to, children}) {
  return (
    <Link to={to}
      className="mr-2 py-2 px-3 rounded hover:bg-purple-50 text-gray-700 hover:text-purple-900"
      activeClassName="bg-purple-200 hover:bg-purple-200 text-purple-900"
    >
        {children}
    </Link>
  )
}

export default function Navigation() {
  return (
    <nav className="text-sm">
      <NavLink to="/">Principles</NavLink>
      <NavLink to="/software">Software</NavLink>
    </nav>
  );
}